/* 👻🚨👻🚨👻 Let op! This code has been auto generated, so best not to touch it 👻🚨👻🚨👻 */
import * as Types from './types';

import type { FetchOptions } from 'codegen/fetcher/fetcher.types'
import { fetcher } from 'codegen/fetcher'
import { useMutation, type UseMutationOptions } from '@tanstack/react-query'
export type AskCfLikeAnswerMutationVariables = Types.Exact<{
  input: Types.AskCfLikeAnswerInput;
}>;


export type AskCfLikeAnswerMutation = { __typename?: 'Mutation', askCFLikeAnswer: { __typename?: 'AskCFLikeAnswerResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string, fields?: Array<{ __typename?: 'FieldError', field: string, errors: Array<string> } | null> | null }> | null } };

export type AskCfDislikeAnswerMutationVariables = Types.Exact<{
  input: Types.AskCfDislikeAnswerInput;
}>;


export type AskCfDislikeAnswerMutation = { __typename?: 'Mutation', askCFDislikeAnswer: { __typename?: 'AskCFDislikeAnswerResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string, fields?: Array<{ __typename?: 'FieldError', field: string, errors: Array<string> } | null> | null }> | null } };

export type AskCfLikeCommentMutationVariables = Types.Exact<{
  input: Types.AskCfLikeCommentInput;
}>;


export type AskCfLikeCommentMutation = { __typename?: 'Mutation', askCFLikeComment: { __typename?: 'AskCFLikeCommentResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string, fields?: Array<{ __typename?: 'FieldError', field: string, errors: Array<string> } | null> | null }> | null } };

export type AskCfDislikeCommentMutationVariables = Types.Exact<{
  input: Types.AskCfDislikeCommentInput;
}>;


export type AskCfDislikeCommentMutation = { __typename?: 'Mutation', askCFDislikeComment: { __typename?: 'AskCFDislikeCommentResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string, fields?: Array<{ __typename?: 'FieldError', field: string, errors: Array<string> } | null> | null }> | null } };


export const AskCfLikeAnswerDocument = `
mutation askCFLikeAnswer($input: AskCFLikeAnswerInput!) {
  askCFLikeAnswer(input: $input) {
    success
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
`

export const askCFLikeAnswerMutationKey = 'askCFLikeAnswer'

export function askCFLikeAnswerMutationFetcher(variables: AskCfLikeAnswerMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<AskCfLikeAnswerMutation, AskCfLikeAnswerMutationVariables>(AskCfLikeAnswerDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useAskCfLikeAnswerMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<AskCfLikeAnswerMutation, E, AskCfLikeAnswerMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<AskCfLikeAnswerMutation, E, AskCfLikeAnswerMutationVariables, C>({
        ...options,
        mutationKey: [askCFLikeAnswerMutationKey],
        mutationFn: (variables: AskCfLikeAnswerMutationVariables) => askCFLikeAnswerMutationFetcher(variables, fetchOptions),
    })
}

export const AskCfDislikeAnswerDocument = `
mutation askCFDislikeAnswer($input: AskCFDislikeAnswerInput!) {
  askCFDislikeAnswer(input: $input) {
    success
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
`

export const askCFDislikeAnswerMutationKey = 'askCFDislikeAnswer'

export function askCFDislikeAnswerMutationFetcher(variables: AskCfDislikeAnswerMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<AskCfDislikeAnswerMutation, AskCfDislikeAnswerMutationVariables>(AskCfDislikeAnswerDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useAskCfDislikeAnswerMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<AskCfDislikeAnswerMutation, E, AskCfDislikeAnswerMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<AskCfDislikeAnswerMutation, E, AskCfDislikeAnswerMutationVariables, C>({
        ...options,
        mutationKey: [askCFDislikeAnswerMutationKey],
        mutationFn: (variables: AskCfDislikeAnswerMutationVariables) => askCFDislikeAnswerMutationFetcher(variables, fetchOptions),
    })
}

export const AskCfLikeCommentDocument = `
mutation askCFLikeComment($input: AskCFLikeCommentInput!) {
  askCFLikeComment(input: $input) {
    success
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
`

export const askCFLikeCommentMutationKey = 'askCFLikeComment'

export function askCFLikeCommentMutationFetcher(variables: AskCfLikeCommentMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<AskCfLikeCommentMutation, AskCfLikeCommentMutationVariables>(AskCfLikeCommentDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useAskCfLikeCommentMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<AskCfLikeCommentMutation, E, AskCfLikeCommentMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<AskCfLikeCommentMutation, E, AskCfLikeCommentMutationVariables, C>({
        ...options,
        mutationKey: [askCFLikeCommentMutationKey],
        mutationFn: (variables: AskCfLikeCommentMutationVariables) => askCFLikeCommentMutationFetcher(variables, fetchOptions),
    })
}

export const AskCfDislikeCommentDocument = `
mutation askCFDislikeComment($input: AskCFDislikeCommentInput!) {
  askCFDislikeComment(input: $input) {
    success
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
`

export const askCFDislikeCommentMutationKey = 'askCFDislikeComment'

export function askCFDislikeCommentMutationFetcher(variables: AskCfDislikeCommentMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<AskCfDislikeCommentMutation, AskCfDislikeCommentMutationVariables>(AskCfDislikeCommentDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useAskCfDislikeCommentMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<AskCfDislikeCommentMutation, E, AskCfDislikeCommentMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<AskCfDislikeCommentMutation, E, AskCfDislikeCommentMutationVariables, C>({
        ...options,
        mutationKey: [askCFDislikeCommentMutationKey],
        mutationFn: (variables: AskCfDislikeCommentMutationVariables) => askCFDislikeCommentMutationFetcher(variables, fetchOptions),
    })
}