'use client'
import * as React from 'react'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import { useAskCfListCommentsQuery } from 'codegen/generated/ask-cf'
import { SpinnerIcon } from 'primitives/icons'
import { useIsLoggedInQuery } from 'analytics/hooks/use-is-logged-in-query'

import { Link } from '@ask-cf/components/link'
import { PostAnswer, PostComment } from '../models'
import { PostCommentInput } from './post-comment-input'
import { PostCommentText } from './post-comment-text'

export function FeedComment({
  answer,
  showExpanded,
  detailsHref,
}: {
  answer: PostAnswer
  showExpanded?: boolean
  detailsHref: string
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const searchParams = useSearchParams()
  const commentIdParam = searchParams.get('display-comment-id') as string
  const displayAnswerId = searchParams.get('display-answer-id')
  const commentParam = searchParams.get('comment')
  const { data: isLoggedIn } = useIsLoggedInQuery()
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [comments, setComments] = React.useState<PostComment[]>([])
  const [activeReplyCommentId, setActiveReplyCommentId] = React.useState<string | null>(null)
  const [focusedReplyCommentId, setFocusedReplyCommentId] = React.useState<string | null>(null)
  const [activeReplyText, setActiveReplyText] = React.useState<{ [key: string]: string }>({})
  const commentBlockRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (commentIdParam && answer.comments) {
      setComments(answer.comments)
    } else {
      setComments([])
    }
  }, [commentIdParam, answer.comments])

  const {
    data: listParentComments,
    isLoading,
    isFetching,
    refetch,
  } = useAskCfListCommentsQuery(
    {
      input: {
        answerId: answer.id,
        itemsPerPage: 3,
        currentPage,
      },
    },
    {
      refetchOnWindowFocus: false,
      enabled: showExpanded && !commentIdParam,
      select: data => data.askCFListComments,
      onSuccess: data => {
        const commentsArray = data?.comments
        if (Array.isArray(commentsArray)) {
          setComments(prevComments => [
            ...prevComments,
            ...commentsArray.filter(
              (comment, index, self) =>
                self.findIndex(c => c.id === comment.id) === index &&
                !prevComments.some(prevComment => prevComment.id === comment.id),
            ),
          ])
        }
      },
    },
  )

  const handleLoadMore = () => {
    const pagination = listParentComments?.pagination
    if (
      pagination?.currentPage &&
      pagination?.totalPages &&
      pagination.currentPage < pagination.totalPages
    ) {
      setCurrentPage(prevPage => prevPage + 1)
    }
  }

  const handleCommentPosted = (newComment: PostComment) => {
    setComments(prevComments => [newComment, ...prevComments])
    refetch()
  }

  const handleHideTextarea = () => {
    setActiveReplyCommentId(null)
    setFocusedReplyCommentId(null)
  }

  React.useEffect(() => {
    if (!isLoggedIn && displayAnswerId === answer.id && commentParam === 'true') {
      if (commentBlockRef.current) {
        const offset = window.innerHeight / 2 - commentBlockRef.current.offsetHeight / 2
        window.scrollTo({
          top: commentBlockRef.current.getBoundingClientRect().top + window.scrollY - offset,
          behavior: 'smooth',
        })
      }
    }
  }, [isLoggedIn, displayAnswerId, commentParam, answer.id])

  return (
    <div ref={commentBlockRef}>
      {commentIdParam ? (
        <div className="py-2 pl-10 pr-2">
          <Link className="text-blue-700 hover:text-blue-800" href={detailsHref}>
            {t('see_all_comments')}
          </Link>
        </div>
      ) : null}
      {isLoggedIn && !commentIdParam ? (
        <div className="pl-10 pr-2">
          <PostCommentInput
            answerId={answer.id}
            onCommentPosted={handleCommentPosted}
            initialText={activeReplyText['main'] || ''}
            onTextChange={text => setActiveReplyText(prev => ({ ...prev, ['main']: text }))}
            isFocused={focusedReplyCommentId === 'main'}
            onFocus={() => setFocusedReplyCommentId('main')}
            onHideTextarea={handleHideTextarea}
          />
        </div>
      ) : null}
      {comments.length ? (
        <div className="py-4 pl-10">
          {comments.map((comment: PostComment) => (
            <PostCommentText
              key={comment.id}
              comment={comment}
              answerId={answer.id}
              activeReplyCommentId={activeReplyCommentId}
              setActiveReplyCommentId={setActiveReplyCommentId}
              activeReplyText={activeReplyText}
              setActiveReplyText={setActiveReplyText}
              focusedReplyCommentId={focusedReplyCommentId}
              setFocusedReplyCommentId={setFocusedReplyCommentId}
            />
          ))}

          {listParentComments?.pagination?.currentPage &&
          listParentComments?.pagination?.totalPages &&
          listParentComments.pagination.currentPage < listParentComments.pagination.totalPages ? (
            <div className="pr-2">
              <button
                className="bg-background-primary mt-4 w-full rounded p-2 text-sm text-blue-500 hover:text-blue-300"
                onClick={handleLoadMore}
                disabled={isLoading || isFetching}
              >
                {isFetching || isLoading ? (
                  <SpinnerIcon className="mx-auto animate-spin" />
                ) : (
                  t('more_comments')
                )}
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
