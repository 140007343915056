import * as Popover from '@radix-ui/react-popover'
import { useWindowDomEvent } from 'hooks/use-dom-event'
import * as React from 'react'
import { callAll } from 'utils/call-all'
import { tw } from 'utils/classnames'

// eslint-disable-next-line no-barrel-files/no-barrel-files
export * from '@radix-ui/react-popover'

type RootProps = Popover.PopoverProps & {
  id?: string
  behaviour?: 'click' | 'hover'
}

type PopoverContextValue = {
  open: boolean
  setOpen(value: boolean): void
  behaviour?: 'click' | 'hover'
  triggerRef: React.RefObject<HTMLButtonElement>
  contentRef: React.RefObject<HTMLDivElement>
}

const PopoverContext = React.createContext<PopoverContextValue | null>(null)

export function usePopoverContext() {
  const context = React.useContext(PopoverContext)

  if (!context) {
    throw new Error(`usePopoverContext() must be called inside <Popover.Root />`)
  }

  return context
}

export function Portal(props: Popover.PopoverPortalProps) {
  return <Popover.Portal {...props} />
}

export function Root({ behaviour = 'click', ...props }: RootProps) {
  const [open, setOpen] = React.useState(false)
  const triggerRef = React.useRef<HTMLButtonElement>(null)
  const contentRef = React.useRef<HTMLDivElement>(null)

  const isControlled = props.open !== undefined

  const context = React.useMemo(() => {
    if (isControlled) {
      return {
        open: props.open!,
        setOpen: props.onOpenChange!,
        behaviour,
        triggerRef,
        contentRef,
      }
    }

    return { open, setOpen, behaviour, triggerRef, contentRef }
  }, [behaviour, isControlled, open, props.onOpenChange, props.open])

  const rootProps = React.useMemo(() => {
    if (isControlled) {
      return props
    }

    return {
      ...props,
      open,
      onOpenChange: setOpen,
    }
  }, [isControlled, open, props])

  return (
    <PopoverContext.Provider value={context}>
      <Popover.Root {...rootProps} data-popover-root />
    </PopoverContext.Provider>
  )
}

function TriggerRoot(props: Popover.PopoverTriggerProps, ref: React.Ref<HTMLButtonElement>) {
  const setOpen = usePopoverContext()?.setOpen
  const behaviour = usePopoverContext()?.behaviour
  const isKeyPress = React.useRef(false)
  const triggerRef = usePopoverContext()?.triggerRef
  const contentRef = usePopoverContext()?.contentRef

  React.useImperativeHandle(ref, () => triggerRef.current!)

  function onMouseLeave(e: Event) {
    if (contentRef?.current && !contentRef.current.contains(e.target as Node)) {
      setOpen?.(false)
    }
  }

  return (
    <Popover.Trigger
      {...props}
      ref={triggerRef}
      onClick={e => {
        // prevent the mouse click from closing the popover when
        // in the 'hover' mode. otherwise a user can click on an
        // a trigger whilst it is open, and it will close the modal
        if (behaviour === 'hover' && !isKeyPress.current) {
          e.preventDefault()
        }
        isKeyPress.current = false
      }}
      data-popover-trigger
      onKeyDown={() => (isKeyPress.current = true)}
      onMouseEnter={callAll(
        behaviour === 'hover' && (() => setOpen?.(true)),
        behaviour === 'hover' && (() => triggerRef?.current?.focus()),
        props.onMouseEnter,
      )}
      onMouseLeave={callAll(behaviour === 'hover' && onMouseLeave, props.onMouseLeave)}
    />
  )
}

export const Trigger = React.forwardRef(TriggerRoot)

// enableClickToClose is a workaround to make the popover stay open on very specific scenarios
// in almost every case you shouldn't need it
function ContentRoot(
  {
    children,
    enableClickToClose = true,
    preventClickOutsideClose = false,
    ...props
  }: React.ComponentProps<typeof Popover.Content> & {
    enableClickToClose?: boolean
    preventClickOutsideClose?: boolean
  },
  ref: React.Ref<HTMLDivElement>,
) {
  const { contentRef, setOpen, triggerRef } = usePopoverContext()
  const behaviour = usePopoverContext()?.behaviour
  const isMouseDown = React.useRef(false)
  const isClickInside = React.useRef(false)

  useWindowDomEvent(
    'mousedown',
    React.useCallback(() => (isMouseDown.current = true), []),
  )

  useWindowDomEvent(
    'mouseup',
    React.useCallback(() => (isMouseDown.current = false), []),
  )

  useWindowDomEvent(
    'mousedown',
    React.useCallback(
      (e: Event) => {
        if (
          enableClickToClose &&
          contentRef?.current &&
          !contentRef.current.contains(e.target as Node) &&
          triggerRef?.current &&
          !triggerRef.current.contains(e.target as Node) &&
          preventClickOutsideClose &&
          !isClickInside.current
        ) {
          setOpen?.(false)
        }
        isClickInside.current = false
      },
      [enableClickToClose, contentRef, triggerRef, setOpen, preventClickOutsideClose],
    ),
  )

  React.useImperativeHandle(ref, () => contentRef.current!)

  const alignOffset = props.alignOffset ? -1 * props.alignOffset : 0
  const sideOffset = props.sideOffset || 0
  const hitboxSize = sideOffset + alignOffset

  return (
    <Popover.Content
      side="bottom"
      // prevent autofocus on popovers for now since it gives them the outline
      onOpenAutoFocus={e => e.preventDefault()}
      {...props}
      ref={contentRef}
      onMouseEnter={callAll(
        behaviour === 'hover' && (() => !isMouseDown.current && setOpen?.(true)),
        props.onMouseEnter,
      )}
      onMouseLeave={callAll(
        behaviour === 'hover' && (() => !isMouseDown.current && setOpen?.(false)),
        props.onMouseLeave,
      )}
      // the after pseudo element acts as a kind of hitbox, and we want the hitbox
      // to start from the top of the content, to the bottom of the trigger. we know this gap
      // from the sideOffset prop, so we set the height of the after element to that value
      style={hitboxSize ? { '--hitbox-size': `${hitboxSize}px` } : {}}
      data-popover-content
      onClickCapture={() => (isClickInside.current = true)}
      className={tw(
        // zindex on all
        'relative z-50',
        // no outline on popover contents
        'outline-none',
        // max height is max available space
        'max-h-[calc(var(--radix-popover-content-available-height)-8px)]',

        // hitbox css, only apply if there are offsets
        alignOffset || sideOffset
          ? props.side === 'right'
            ? `after:absolute after:-left-[--hitbox-size] after:top-0 after:h-full after:w-[--hitbox-size] after:content-[""]`
            : props.side === 'bottom' || !props.side
            ? 'after:absolute after:-top-[--hitbox-size] after:left-0 after:h-[--hitbox-size] after:w-full after:content-[""]'
            : null
          : null,

        // ensure hitbox is above the content
        'after:z-10',
        props.className,
      )}
    >
      {children}
    </Popover.Content>
  )
}

export const Content = React.forwardRef(ContentRoot)
