'use client'
/* eslint-disable @next/next/no-img-element */
import * as React from 'react'
import { useTranslations } from 'next-intl'
import { useAskCfListCommentsQuery } from 'codegen/generated/ask-cf'
import { motion } from 'framer-motion'
import { SpinnerIcon } from 'primitives/icons'
import { useIsLoggedInQuery } from 'analytics/hooks/use-is-logged-in-query'
import { IconButton } from 'primitives/buttons/icon-button'
import { tw } from 'utils/classnames'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'

import { useAskCfDislikeCommentMutation, useAskCfLikeCommentMutation } from '@ask-cf/gen/ask-cf'
import { ASK_CF_FF_ANSWER_LIKES } from '@ask-cf/config'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { PostHeader } from '@ask-cf/components/post-header'
import { ChatIcon } from '@ask-cf/assets/icons'
import { LikeDislikeButtons } from './like-dislike-buttons'
import { PostCommentInput } from './post-comment-input'
import { PostComment } from '../models'

export function PostCommentText({
  comment,
  answerId,
  level = 0,
  activeReplyCommentId,
  setActiveReplyCommentId,
  activeReplyText,
  setActiveReplyText,
  focusedReplyCommentId,
  setFocusedReplyCommentId,
}: {
  comment: PostComment
  answerId: string
  level?: number
  activeReplyCommentId: string | null
  setActiveReplyCommentId: React.Dispatch<React.SetStateAction<string | null>>
  activeReplyText: { [key: string]: string }
  setActiveReplyText: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
  focusedReplyCommentId: string | null
  setFocusedReplyCommentId: React.Dispatch<React.SetStateAction<string | null>>
}) {
  const [replyPage, setReplyPage] = React.useState<number>(1)
  const [replies, setReplies] = React.useState<PostComment[]>([])
  const t = useTranslations('ask_cf.components.feed.post')
  const { data: isLoggedIn } = useIsLoggedInQuery()
  const { openModal } = useUserAuthModal()
  const { setMessage } = useNotifyLoginDialog()

  const {
    data: repliesData,
    isFetching,
    isLoading,
    refetch,
  } = useAskCfListCommentsQuery(
    {
      input: {
        answerId: answerId,
        parentCommentId: comment.id,
        itemsPerPage: 3,
        currentPage: replyPage,
      },
    },
    {
      refetchOnWindowFocus: false,
      enabled: (comment?.commentCount ?? 0) > 0,
      select: data => data.askCFListComments,
      onSuccess: data => {
        const commentsArray = data?.comments
        if (Array.isArray(commentsArray)) {
          setReplies(prevReplies => [
            ...prevReplies,
            ...commentsArray.filter(
              (reply, index, self) =>
                self.findIndex(r => r.id === reply.id) === index &&
                !prevReplies.some(prevReply => prevReply.id === reply.id),
            ),
          ])
        }
      },
    },
  )

  const handleLoadMoreReplies = () => {
    const pagination = repliesData?.pagination
    if (
      pagination?.currentPage &&
      pagination?.totalPages &&
      pagination.currentPage < pagination.totalPages
    ) {
      setReplyPage(prevPage => prevPage + 1)
    }
  }

  const handleReplyClick = () => {
    if (!isLoggedIn) {
      setMessage(t('log_in_dialog_title_reply'))
      openModal('login', {
        modalOpenChangeCallback: () => (open: boolean) => {
          if (!open) {
            setMessage('')
          }
        },
      })
    }
    setActiveReplyCommentId(comment.id)
    setFocusedReplyCommentId(comment.id)
  }

  const handleReplyPosted = (newReply: PostComment) => {
    setReplies(prevReplies => [newReply, ...prevReplies])
    refetch()
    setActiveReplyText(prev => ({ ...prev, [comment.id]: '' }))
  }

  const handleHideTextarea = () => {
    setActiveReplyCommentId(null)
    setFocusedReplyCommentId(null)
  }

  return (
    <>
      <CommentItem comment={comment} level={level} onReplyClick={handleReplyClick} />

      {isLoggedIn && (activeReplyCommentId === comment.id || activeReplyText[comment.id]) ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="relative mb-4">
          <PostCommentInput
            answerId={answerId}
            commentParentId={comment.id}
            onCommentPosted={handleReplyPosted}
            initialText={activeReplyText[comment.id] || ''}
            onTextChange={(text: string) =>
              setActiveReplyText(prev => ({ ...prev, [comment.id]: text }))
            }
            isFocused={focusedReplyCommentId === comment.id}
            onFocus={() => setFocusedReplyCommentId(comment.id)}
            onHideTextarea={handleHideTextarea}
          />
        </motion.div>
      ) : null}

      {replies.length > 0 ? (
        <div className={tw(level === 0 && 'pl-10')}>
          {replies.map((reply: PostComment) => (
            <PostCommentText
              key={reply.id}
              comment={reply}
              answerId={answerId}
              level={1}
              activeReplyCommentId={activeReplyCommentId}
              setActiveReplyCommentId={setActiveReplyCommentId}
              activeReplyText={activeReplyText}
              setActiveReplyText={setActiveReplyText}
              focusedReplyCommentId={focusedReplyCommentId}
              setFocusedReplyCommentId={setFocusedReplyCommentId}
            />
          ))}
        </div>
      ) : null}

      {repliesData?.pagination?.currentPage &&
      repliesData?.pagination?.totalPages &&
      repliesData.pagination.currentPage < repliesData.pagination.totalPages ? (
        <button
          className="bg-background-primary my-4 w-full rounded p-2 text-sm text-blue-500 hover:text-blue-300"
          onClick={handleLoadMoreReplies}
          disabled={isLoading || isFetching}
        >
          {isFetching || isLoading ? (
            <SpinnerIcon className="mx-auto animate-spin" />
          ) : (
            t('more_reply')
          )}
        </button>
      ) : null}
    </>
  )
}

function CommentItem({
  comment,
  level,
  onReplyClick,
}: {
  comment: PostComment
  level: number
  onReplyClick: () => void
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const { user } = comment
  const askCfLikeCommentMutation = useAskCfLikeCommentMutation()
  const askCfDislikeCommentMutation = useAskCfDislikeCommentMutation()

  function handleLikeClick() {
    if (!comment.isLiked) {
      askCfLikeCommentMutation.mutate(
        { input: { commentId: comment.id } },
        {
          onSuccess: data => {
            if (data.askCFLikeComment.success) {
              comment.isLiked = true
              comment.upvotes += 1
              comment.isDisliked = false // Ensure dislike is cleared if it was set
            }
          },
        },
      )
    }
  }

  function handleDislikeClick() {
    if (comment.isLiked) {
      askCfDislikeCommentMutation.mutate(
        { input: { commentId: comment.id } },
        {
          onSuccess: data => {
            if (data.askCFDislikeComment.success) {
              comment.isLiked = false
              comment.upvotes -= 1
            }
          },
        },
      )
    } else {
      // Toggle dislike without affecting upvotes if it was not liked
      askCfDislikeCommentMutation.mutate(
        { input: { commentId: comment.id } },
        {
          onSuccess: data => {
            if (data.askCFDislikeComment.success) {
              comment.isDisliked = !comment.isDisliked
            }
          },
        },
      )
    }
  }

  return (
    <div className={`relative ${level === 1 ? 'pl-10' : 'pl-5'}`}>
      {level === 1 ? (
        <div className="absolute -left-1 top-2 h-6 w-4 rounded-bl-sm border-b-2 border-l-2 border-gray-300"></div>
      ) : null}
      <PostHeader user={user} postedAt={comment.createTime} />
      <div className="pt-3">
        <p className="pb-4 pl-1">{comment.comment}</p>
        {comment.asset ? <img alt="image" src={comment.asset.url} /> : null}
        <div className="flex py-3">
          {ASK_CF_FF_ANSWER_LIKES ? (
            <div className="text-text-tertiary flex items-center gap-2 rounded-[100px] px-3 py-[5px]">
              <LikeDislikeButtons
                upvotes={comment.upvotes}
                onLikeClick={handleLikeClick}
                onDislikeClick={handleDislikeClick}
                message={t('log_in_dialog_title_comment_like')}
                isLiked={comment.isLiked}
                isDisliked={comment.isDisliked}
              />
            </div>
          ) : null}
          <IconButton
            variant="transparent"
            className="hover:text-text-hover text-text-tertiary flex items-center gap-1 p-0"
            aria-label={t('reply_label')}
            onClick={onReplyClick}
          >
            <ChatIcon />
            <span className="">{t('reply_label')}</span>
          </IconButton>
        </div>
      </div>
    </div>
  )
}
