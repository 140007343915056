import * as React from 'react'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { StudioButton } from 'components/studio-button'
import { useIsLoggedInQuery } from 'analytics/hooks/use-is-logged-in-query'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'

import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { ChatIcon } from '@ask-cf/assets/icons'
import { PostAnswer } from '../feed/models'

export function CommentButton({
  showExpanded,
  detailsHref,
  answer,
}: {
  showExpanded: boolean
  detailsHref: string
  answer: PostAnswer
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const { data: isLoggedIn } = useIsLoggedInQuery()
  const { openModal } = useUserAuthModal()
  const { setMessage } = useNotifyLoginDialog()

  const handleLoginDialogOpen = () => {
    setMessage(t('log_in_dialog_title_comment'))
    openModal('login', {
      modalOpenChangeCallback: () => (open: boolean) => {
        if (!open) {
          setMessage('')
        }
      },
    })
  }

  const handleCommentClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const urlWithComment = `${detailsHref}${detailsHref.includes('?') ? '&' : '?'}comment=true`
    window.open(urlWithComment, '_blank')
  }

  return showExpanded ? (
    isLoggedIn ? (
      <div className="flex items-center gap-1 p-0">
        <ChatIcon />
        <span className="hidden xl:inline-block">
          {t.rich('comments', { count: answer.commentCount })}
        </span>
      </div>
    ) : (
      <StudioButton
        variant="invisible"
        className="hover:text-text-hover active:text-text-tertiary flex h-[42px] items-center gap-1 p-0"
        onClick={handleLoginDialogOpen}
      >
        <ChatIcon />
        <span className="hidden xl:inline-block">{t('comment_label')}</span>
      </StudioButton>
    )
  ) : (
    <Link
      href={detailsHref}
      target="_blank"
      className="hover:text-text-hover flex items-center gap-1 p-0"
      aria-label={t('comment_label')}
      onClick={handleCommentClick}
    >
      <ChatIcon />
      {answer?.commentCount && answer?.commentCount > 0 ? (
        <span className="hidden xl:inline-block">
          {t.rich('comments', { count: answer.commentCount })}
        </span>
      ) : (
        <span className="hidden xl:inline-block">{t('comment_label')}</span>
      )}
    </Link>
  )
}
