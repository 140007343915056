import * as React from 'react'
import { MarkdownViewer } from 'primitives/markdown-viewer'
import { useLocale } from 'next-intl'
import { useInView } from 'framer-motion'

import { FadingOverflowContainer } from '@ask-cf/components/fading-overflow-container'
import { ROUTES } from '@ask-cf/constants/app-routes'

export function FadingOverflowText({
  content,
  detailsHref,
  showExpanded,
  viewMoreLabel,
  overrideIsInView,
  isHtml,
}: {
  content?: string
  detailsHref: string
  viewMoreLabel: string
  showExpanded?: boolean
  overrideIsInView?: boolean
  isHtml?: boolean
}) {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const isInViewPort = useInView(containerRef, { once: true, margin: '200px' })
  const locale = useLocale()

  if (!content) {
    return null
  }

  const ContentDisplay = () => (
    <div className="text-text-secondary text-md whitespace-pre-wrap leading-5 [overflow-wrap:anywhere]">
      {isHtml ? (
        <div dangerouslySetInnerHTML={{ __html: content }} className={'markdown-viewer'}></div>
      ) : (
        <MarkdownViewer redirectUrlBase={`${ROUTES.REDIRECT_BASE(locale)}?href=`}>
          {content}
        </MarkdownViewer>
      )}
    </div>
  )

  return (
    <div ref={containerRef}>
      {!isInViewPort && !overrideIsInView ? (
        // Render simple placeholder until the component is in view
        <div className="text-text-secondary text-md max-h-[60px] overflow-hidden whitespace-pre-wrap leading-5 [overflow-wrap:anywhere] xl:max-h-[72px]">
          {content}
        </div>
      ) : showExpanded ? (
        <div className="text-text-context text-md mt-3 overflow-hidden leading-5 [overflow-wrap:anywhere] xl:mt-4">
          <ContentDisplay />
        </div>
      ) : (
        <FadingOverflowContainer
          viewMoreHref={detailsHref}
          viewMoreLabel={viewMoreLabel}
          className="text-md mt-3 xl:mt-4"
        >
          <ContentDisplay />
        </FadingOverflowContainer>
      )}
    </div>
  )
}
