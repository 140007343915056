/* 👻🚨👻🚨👻 Let op! This code has been auto generated, so best not to touch it 👻🚨👻🚨👻 */
import * as Types from './types';

import type { FetchOptions } from 'codegen/fetcher/fetcher.types'
import { fetcher } from 'codegen/fetcher'
import { useMutation, type UseMutationOptions } from '@tanstack/react-query'
export type AskCfGiveTipToAnswerMutationVariables = Types.Exact<{
  input: Types.AskCfGiveTipToAnswerInput;
}>;


export type AskCfGiveTipToAnswerMutation = { __typename?: 'Mutation', askCFGiveTipToAnswer: { __typename?: 'AskCFGiveTipToAnswerResponse', success: boolean, errors?: Array<{ __typename?: 'ErrorMessage', code: Types.ErrorCode, message: string, fields?: Array<{ __typename?: 'FieldError', field: string, errors: Array<string> } | null> | null }> | null } };


export const AskCfGiveTipToAnswerDocument = `
mutation askCfGiveTipToAnswer($input: AskCFGiveTipToAnswerInput!) {
  askCFGiveTipToAnswer(input: $input) {
    success
    errors {
      code
      message
      fields {
        field
        errors
      }
    }
  }
}
`

export const askCfGiveTipToAnswerMutationKey = 'askCfGiveTipToAnswer'

export function askCfGiveTipToAnswerMutationFetcher(variables: AskCfGiveTipToAnswerMutationVariables, fetchOptions?: FetchOptions) {
    return fetcher<AskCfGiveTipToAnswerMutation, AskCfGiveTipToAnswerMutationVariables>(AskCfGiveTipToAnswerDocument, variables, fetchOptions?.headers, fetchOptions)()
}

export function useAskCfGiveTipToAnswerMutation<E = unknown, C = unknown>(
    options?: Omit<UseMutationOptions<AskCfGiveTipToAnswerMutation, E, AskCfGiveTipToAnswerMutationVariables, C>, 'mutationKey' | 'mutationFn'>,
    fetchOptions?: FetchOptions
) {
    return useMutation<AskCfGiveTipToAnswerMutation, E, AskCfGiveTipToAnswerMutationVariables, C>({
        ...options,
        mutationKey: [askCfGiveTipToAnswerMutationKey],
        mutationFn: (variables: AskCfGiveTipToAnswerMutationVariables) => askCfGiveTipToAnswerMutationFetcher(variables, fetchOptions),
    })
}