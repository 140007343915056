import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'
import { SkeletonItem } from 'components/skeleton-item'

import { FireIcon, PsychologyIcon, TrendingUpIcon } from '@ask-cf/assets/icons'
import { StyledButton } from '@ask-cf/components/styled-button'
import { FeedSortingOption, useFeedSorting } from '@ask-cf/contexts/feed-sorting-context'
import { ASK_CF_FF_FEED_SORT_UNANSWERED } from '@ask-cf/config'

export function FeedSortButtons({ onItemClick }: { onItemClick?: () => void }) {
  const t = useTranslations('ask_cf.components.feed')
  const { activeSortOption, setActiveSortOption } = useFeedSorting()

  const handleClick = (sortOption: FeedSortingOption) => {
    setActiveSortOption(sortOption)
    onItemClick?.()
  }

  return (
    <div className="m-2 mt-0 flex flex-col justify-start xl:m-0 xl:w-full xl:flex-row xl:flex-wrap xl:gap-2">
      <StyledButton
        variant="fill"
        size="medium"
        className={tw(
          'relative justify-start gap-1 border-0 p-2 text-left xl:flex-grow xl:justify-center xl:p-3',
          activeSortOption === 'discovery-feed'
            ? 'text-text-blue bg-blue-200 font-medium'
            : 'xl:hover:text-text-primary hover:text-text-hover bg-[#FAFBFFFA] enabled:hover:bg-transparent xl:enabled:hover:bg-blue-200',
        )}
        onClick={() => handleClick('discovery-feed')}
      >
        <FireIcon />
        <span className="text-sm leading-[20px]">{t('discovery_feed')}</span>
        {activeSortOption === 'discovery-feed' ? (
          <div className="rounded-t-2 absolute bottom-0 left-0 right-0 h-1 bg-blue-400" />
        ) : null}
      </StyledButton>
      <StyledButton
        variant="fill"
        size="medium"
        className={tw(
          'relative justify-start gap-1 border-0 p-2 xl:flex-grow xl:justify-center xl:p-3',
          activeSortOption === 'your-feed'
            ? 'text-text-blue bg-white font-medium'
            : 'xl:hover:text-text-primary hover:text-text-hover bg-[#FAFBFFFA] enabled:hover:bg-transparent xl:enabled:hover:bg-blue-200',
        )}
        onClick={() => handleClick('your-feed')}
      >
        <TrendingUpIcon />
        <span className="text-sm leading-[20px]">{t('your_feed')}</span>
        {activeSortOption === 'your-feed' ? (
          <div className="rounded-t-2 absolute bottom-0 left-0 right-0 h-1 bg-blue-400" />
        ) : null}
      </StyledButton>
      {ASK_CF_FF_FEED_SORT_UNANSWERED ? (
        <StyledButton
          variant="fill"
          size="medium"
          className={tw(
            'relative justify-start border-0 p-2 text-left xl:flex-grow xl:justify-center xl:p-3',
            activeSortOption === 'un-answered'
              ? 'text-text-blue bg-blue-200 font-medium'
              : 'xl:hover:text-text-primary hover:text-text-hover bg-[#FAFBFFFA] enabled:hover:bg-transparent xl:enabled:hover:bg-blue-200',
          )}
          onClick={() => handleClick('un-answered')}
        >
          <PsychologyIcon />
          <span className="text-sm leading-[20px]">{t('un_answered')}</span>
          {activeSortOption === 'un-answered' ? (
            <div className="rounded-t-2 absolute bottom-0 left-0 right-0 h-1 bg-blue-400" />
          ) : null}
        </StyledButton>
      ) : null}
    </div>
  )
}

export function FeedSortButtonsSkeleton() {
  return (
    <div className="m-2 mt-0 flex flex-col justify-start xl:m-0 xl:flex-row xl:flex-wrap xl:gap-2">
      <SkeletonItem className="h-12 w-full rounded-md xl:w-40" />
      <SkeletonItem className="h-12 w-full rounded-md xl:w-40" />
      <SkeletonItem className="h-12 w-full rounded-md xl:w-40" />
    </div>
  )
}
