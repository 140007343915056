import * as React from 'react'
import { tw } from 'utils/classnames'
import { useTranslations } from 'next-intl'
import { StudioButton } from 'components/studio-button'
import { useSearchParams } from 'next/navigation'

import { useAskCfLikeAnswerMutation, useAskCfDislikeAnswerMutation } from '@ask-cf/gen/ask-cf'
import { PostAnswer, ticketGenieUser } from '../models'
import { ShareIcon } from '@ask-cf/assets/icons'
import { PostHeader } from '../../post-header'
import {
  ASK_CF_FF_ANSWER_LIKES,
  ASK_CF_FF_ANSWER_TIPS,
  ASK_CF_FF_COMMENTS,
  ASK_CF_URL,
} from '@ask-cf/config'
import { useSocialShareDialog } from '@ask-cf/contexts/social-share-dialog-context'
import { TipButton } from '@ask-cf/components/tip-button'
import { CommentButton } from '@ask-cf/components/comment-button'
import { PostImageGallery } from './post-image-gallery'
import { useUser } from '@ask-cf/hooks/use-user-data'
import { FadingOverflowText } from './fading-overflow-text'
import { FeedComment } from './feed-comment'
import { LikeDislikeButtons } from './like-dislike-buttons'

export function FeedAnswer({
  answer,
  showExpanded,
  questionDetailsHref,
  showOwnAnswerExpanded,
  overrideIsInView,
}: {
  answer: PostAnswer
  showExpanded: boolean
  questionDetailsHref: string
  showOwnAnswerExpanded: boolean
  overrideIsInView?: boolean
}) {
  const id = useSearchParams().get('display-answer-id')
  const commentParam = useSearchParams().get('comment')
  const user = useUser()
  const isOwnAnswer = user?.data?.id === answer.user?.id

  const answerRef = React.useRef<HTMLDivElement>(null)

  const detailsWithAnswerId = `${questionDetailsHref}?display-answer-id=${answer.id}`

  React.useEffect(() => {
    // Added the window.scrollY < 5 condition to prevent scrolling to the answer when the user has already scrolled down
    if (
      id &&
      answer.id === id &&
      answerRef.current &&
      showExpanded &&
      window.scrollY < 5 &&
      commentParam !== 'true'
    ) {
      const headerHeight = 180
      const elementPosition = answerRef.current.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - headerHeight - 20

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }, [id, answer.id, showExpanded, commentParam])

  const shouldShowExpanded = showExpanded || (isOwnAnswer && showOwnAnswerExpanded)

  return (
    <div
      className={tw(
        'relative mt-4 flex flex-col gap-4 xl:my-4',
        answer.isBestAnswer ? 'shadow-best-answer rounded-r-2 px-4 pb-4 pt-6 xl:px-6 xl:pb-6' : '',
      )}
      ref={answerRef}
    >
      <div
        className={tw(
          'rounded-l-2 absolute text-sm leading-5',
          answer.isBestAnswer
            ? 'bg-gradient-best-answer bottom-0 left-0 top-0 w-2'
            : 'bg-gradient-answer bottom-2 left-4 top-[54px] w-0.5',
        )}
      />

      <PostHeader
        user={answer.user || ticketGenieUser}
        postedAt={answer.createTime}
        isBestAnswerHeader={answer.isBestAnswer}
      />
      <div className={tw(answer.isBestAnswer ? 'pl-2' : 'pl-10')}>
        <AnswerContent
          content={answer.answer}
          showExpanded={shouldShowExpanded}
          detailsHref={detailsWithAnswerId}
          overrideIsInView={overrideIsInView}
          isHtmlAnswer={answer.isHtmlAnswer}
        />
        {answer.assets?.length ? (
          <PostImageGallery
            imageUrls={answer.assets?.map(asset => asset.optimizedUrl || asset.url)}
          />
        ) : null}
        <div className="mt-4 px-3">
          <AnswerActions
            answer={answer}
            detailsHref={detailsWithAnswerId}
            showExpanded={showExpanded}
          />
        </div>
      </div>
      {showExpanded && ASK_CF_FF_COMMENTS ? (
        <FeedComment
          answer={answer}
          showExpanded={showExpanded}
          detailsHref={detailsWithAnswerId}
        />
      ) : null}
    </div>
  )
}

function AnswerContent({
  content,
  detailsHref,
  showExpanded,
  overrideIsInView,
  isHtmlAnswer,
}: {
  content?: string
  detailsHref: string
  showExpanded?: boolean
  overrideIsInView?: boolean
  isHtmlAnswer?: boolean
}) {
  const t = useTranslations('ask_cf.components.feed.post')

  return (
    <FadingOverflowText
      content={content}
      detailsHref={detailsHref}
      showExpanded={showExpanded}
      viewMoreLabel={t('view_more_label')}
      overrideIsInView={overrideIsInView}
      isHtml={isHtmlAnswer}
    />
  )
}

function AnswerActions({
  answer,
  detailsHref,
  showExpanded,
}: {
  answer: PostAnswer
  detailsHref: string
  showExpanded: boolean
}) {
  const absoluteDetailsHref = `${ASK_CF_URL}${detailsHref}`
  const t = useTranslations('ask_cf.components.feed.post')
  const { openDialog } = useSocialShareDialog()
  const askCfLikeAnswerMutation = useAskCfLikeAnswerMutation()
  const askCfDislikeAnswerMutation = useAskCfDislikeAnswerMutation()
  const { data: userData } = useUser()

  const isCurrentUsersAnswer = userData?.id === answer.user?.id

  function handleLikeClick() {
    if (!answer.isLiked) {
      askCfLikeAnswerMutation.mutate(
        { input: { answerId: answer.id } },
        {
          onSuccess: data => {
            if (data.askCFLikeAnswer.success) {
              answer.isLiked = true
              answer.upvotes += 1
              answer.isDisliked = false // Ensure dislike is cleared if it was set
            }
          },
        },
      )
    }
  }

  function handleDislikeClick() {
    if (answer.isLiked) {
      askCfDislikeAnswerMutation.mutate(
        { input: { answerId: answer.id } },
        {
          onSuccess: data => {
            if (data.askCFDislikeAnswer.success) {
              answer.isLiked = false
              answer.upvotes -= 1
            }
          },
        },
      )
    } else {
      // Toggle dislike without affecting upvotes if it was not liked
      askCfDislikeAnswerMutation.mutate(
        { input: { answerId: answer.id } },
        {
          onSuccess: data => {
            if (data.askCFDislikeAnswer.success) {
              answer.isDisliked = !answer.isDisliked
            }
          },
        },
      )
    }
  }

  return (
    <div className="flex items-center gap-4" key={`${answer.id}-${answer.upvotes}`}>
      {ASK_CF_FF_ANSWER_LIKES ? (
        <LikeDislikeButtons
          upvotes={answer.upvotes}
          onLikeClick={handleLikeClick}
          onDislikeClick={handleDislikeClick}
          isLiked={answer.isLiked}
          isDisliked={answer.isDisliked}
        />
      ) : null}
      <div className="text-text-tertiary flex items-center gap-5 text-sm font-medium leading-5">
        {ASK_CF_FF_ANSWER_TIPS && !isCurrentUsersAnswer ? <TipButton answerId={answer.id} /> : null}
        {ASK_CF_FF_COMMENTS ? (
          <CommentButton showExpanded={showExpanded} detailsHref={detailsHref} answer={answer} />
        ) : null}
        <StudioButton
          variant="invisible"
          className="hover:text-text-hover flex h-[42px] items-center gap-1 p-0"
          onClick={() => openDialog(absoluteDetailsHref)}
          aria-label={t('share_answer_label')}
        >
          <ShareIcon width={16} height={16} />
          <span className="hidden xl:inline-block">{t('share_answer_label')}</span>
        </StudioButton>
      </div>
    </div>
  )
}
