'use client'
import { useTranslations } from 'next-intl'
import { IconButton } from 'primitives/buttons/icon-button'
import { useIsLoggedInQuery } from 'analytics/hooks/use-is-logged-in-query'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'
import { tw } from 'utils/classnames'

import { ThumbUpIcon, ThumbDownIcon } from '@ask-cf/assets/icons'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'

export function LikeDislikeButtons({
  upvotes,
  onLikeClick,
  onDislikeClick,
  message,
  isLiked,
  isDisliked,
}: {
  upvotes: number
  onLikeClick: () => void
  onDislikeClick: () => void
  message?: string
  isLiked: boolean
  isDisliked: boolean
}) {
  const t = useTranslations('ask_cf.components.feed.post')
  const { data: isLoggedIn } = useIsLoggedInQuery()
  const { openModal } = useUserAuthModal()
  const { setMessage } = useNotifyLoginDialog()

  const handleLoginDialogOpen = () => {
    setMessage(message || t('log_in_dialog_title_like'))
    openModal('login', {
      modalOpenChangeCallback: () => (open: boolean) => {
        if (!open) {
          setMessage('')
        }
      },
    })
  }

  function handleLikeClick() {
    if (isLoggedIn) {
      onLikeClick()
    } else {
      handleLoginDialogOpen()
    }
  }

  function handleDislikeClick() {
    if (isLoggedIn) {
      onDislikeClick()
    } else {
      handleLoginDialogOpen()
    }
  }

  return (
    <div className="text-text-tertiary flex items-center gap-2 rounded-[100px] bg-[#F3F5F9] px-3 py-[5px]">
      <IconButton
        variant="transparent"
        className={tw(
          'text-text-tertiary hover:text-text-tertiary size-auto p-0',
          isLiked && 'text-content-text-link',
        )}
        aria-label={t('thumb_up_aria_label')}
        asChild
        onClick={handleLikeClick}
      >
        <ThumbUpIcon />
      </IconButton>
      <div className="text-sm leading-5">{upvotes}</div>
      <IconButton
        variant="transparent"
        className={tw(
          'text-text-tertiary hover:text-text-tertiary size-auto p-0',
          isDisliked && 'text-content-text-link',
        )}
        aria-label={t('thumb_down_aria_label')}
        asChild
        onClick={handleDislikeClick}
      >
        <ThumbDownIcon />
      </IconButton>
    </div>
  )
}
