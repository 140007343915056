import * as React from 'react'
import Markdown from 'react-markdown'
import './markdown-viewer.css'

import { UnderlinePlugin } from './plugins/underline.plugin'

const CF_MAIN_DOMAIN = 'creativefabrica.com'
const ALLOWED_DOMAINS_CONFIG = [
  {
    domain: 'instagram.com',
    allowedPath: '/creativefabrica',
  },
  {
    domain: 'facebook.com',
    allowedPath: '/creativefabricacom',
  },
  {
    domain: 'pinterest.com',
    allowedPath: '/creafabrica',
  },
  {
    domain: 'youtube.com',
    allowedPath: '/@creativefabricastudio',
  },
  {
    domain: 'linkedin.com',
    allowedPath: '/company/creative-fabrica',
  },
  {
    domain: 'tiktok.com',
    allowedPath: '/@creativefabrica.com',
  },
]

export function MarkdownViewer({
  children,
  redirectUrlBase,
}: {
  children: string
  redirectUrlBase?: string
}) {
  const markdown = children
    // Clean from emoji markers due to differences in rendering between react-markdown and our editor
    .replaceAll('```', '')
    // Insert zero-width space between two newlines to prevent the editor from collapsing them, but keep double newlines after lists as
    // a marker to end a list
    .replaceAll(/\n(?= *\n)/g, (match, offset, string) => {
      // If the character before the current '\n' is '\n', we've already passed the first '\n' after the list item
      if (string[offset - 1] === '\n') {
        return '\n&#8287;'
      }

      const prevLineEnd = offset - 1

      const prevLineStart = string.lastIndexOf('\n', prevLineEnd - 1) + 1

      const prevLine = string.substring(prevLineStart, prevLineEnd + 1).trim()

      // Check if the previous line is a list item
      if (/^(-|\d+\.)/.test(prevLine)) {
        // Do not insert '&#8287;' after the first '\n' following a list item
        return '\n'
      } else {
        return '\n&#8287;'
      }
    })
    // Replace all newlines with two spaces and a newline character to prevent the editor from collapsing them (markdown)
    .replaceAll(/\n/gi, '  \n')

  function isValidUrl(href: string): boolean {
    try {
      new URL(href)
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <Markdown
      className={'markdown-viewer'}
      remarkPlugins={[UnderlinePlugin]}
      components={{
        code: 'u', // Render underline for code due to differences between our editor and react-markdown
        a: ({ href, children }) => {
          if (!href || !isValidUrl(href)) {
            // just render, without link styling
            return <>children</>
          }

          const parsedUrl = new URL(href)

          let isInAllowedDomain = false

          isInAllowedDomain = parsedUrl.hostname.toLowerCase().includes(CF_MAIN_DOMAIN)

          if (!isInAllowedDomain) {
            for (const domainConfig of ALLOWED_DOMAINS_CONFIG) {
              if (
                parsedUrl.hostname.toLowerCase().includes(domainConfig.domain) &&
                (parsedUrl.pathname.toLowerCase() === domainConfig.allowedPath ||
                  parsedUrl.pathname.toLowerCase().startsWith(`${domainConfig.allowedPath}/`))
              ) {
                isInAllowedDomain = true
                break
              }
            }
          }

          const rel = isInAllowedDomain ? '' : 'nofollow'
          const url = isInAllowedDomain ? href : `${redirectUrlBase}${href}`
          const target = isInAllowedDomain ? '_blank' : '_self'

          return (
            <a href={url} rel={rel} target={target}>
              {children}
            </a>
          )
        },
      }}
    >
      {markdown}
    </Markdown>
  )
}
