'use client'

import * as React from 'react'

export interface NotEnoughCoinsDialogContextModel {
  isOpen: boolean
  openDialog: () => void
  closeDialog: () => void
}

const NotEnoughCoinsDialogContext = React.createContext<NotEnoughCoinsDialogContextModel>(null!)

export const NotEnoughCoinsDialogProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const openDialog = () => {
    setIsOpen(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  return (
    <NotEnoughCoinsDialogContext.Provider value={{ isOpen, openDialog, closeDialog }}>
      {children}
    </NotEnoughCoinsDialogContext.Provider>
  )
}

export const useNotEnoughCoinsDialog = () => {
  const context = React.useContext(NotEnoughCoinsDialogContext)

  if (context === undefined) {
    throw new Error('useNotEnoughCoinsDialog must be used within a NotEnoughCoinsDialogProvider')
  }

  return context
}
