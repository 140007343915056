'use client'
import * as React from 'react'
import * as Popover from 'primitives/popover'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { motion } from 'framer-motion'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import { IconButton } from 'primitives/buttons/icon-button'
import { tw } from 'utils/classnames'
import { useAskCfPostCommentMutation } from 'codegen/generated/ask-cf'
import { SpinnerIcon } from 'primitives/icons'

import { useUploadsImage } from '@ask-cf/hooks/use-image-upload'
import { EmojiPlusIcon, PhotoPlusIcon, SendIcon, DeleteIcon } from '@ask-cf/assets/icons'
import { PostComment } from '../models'

export function PostCommentInput({
  answerId,
  commentParentId,
  onCommentPosted,
  initialText = '',
  onTextChange,
  isFocused,
  onFocus,
  onHideTextarea,
}: {
  answerId: string
  commentParentId?: string
  onCommentPosted: (newComment: PostComment) => void
  initialText?: string
  onTextChange: (text: string) => void
  isFocused?: boolean
  onFocus?: () => void
  onHideTextarea: () => void
}) {
  const { uploadImages, loading } = useUploadsImage()
  const [comment, setComment] = React.useState<string>(initialText)
  const [selectedImages, setSelectedImages] = React.useState<{ [key: string]: File | null }>({})
  const [imageUrls, setImageUrls] = React.useState<{ [key: string]: string | null }>({})
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  // eslint-disable-next-line local/codegen-mutation-patterns
  const useAskCfPostComment = useAskCfPostCommentMutation()
  const searchParams = useSearchParams()
  const [imageLoading, setImageLoading] = React.useState(false)

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    const newText = comment + emojiData.emoji
    setComment(newText)
    onTextChange(newText)
  }

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageLoading(true)
      const newImage = e.target.files[0]
      setSelectedImages(prevState => ({
        ...prevState,
        [commentParentId || 'main']: newImage,
      }))
      const uploadedFiles = await uploadImages([newImage])
      setImageUrls(prevState => ({
        ...prevState,
        [commentParentId || 'main']: uploadedFiles[0].uploadedUrl,
      }))
      setImageLoading(false)
    }
  }

  const deleteImage = (parentId: string | undefined) => {
    setSelectedImages(prevState => ({
      ...prevState,
      [parentId || 'main']: null,
    }))
    setImageUrls(prevState => ({
      ...prevState,
      [parentId || 'main']: null,
    }))
  }

  const handleSendComment = async () => {
    if (!comment.trim() || !isFocused || imageLoading) {
      return
    }

    useAskCfPostComment.mutate(
      {
        input: {
          answerId,
          comment: comment.trim(),
          comment_parent_id: commentParentId ? commentParentId : undefined,
          imageUrl: imageUrls[commentParentId || 'main'] || undefined,
        },
      },
      {
        onSuccess: data => {
          const newComment = data?.askCFPostComment?.comment

          if (newComment && newComment.id && newComment.comment) {
            setComment('')
            deleteImage(commentParentId)
            onTextChange('')
            onCommentPosted(newComment as PostComment)

            if (commentParentId) {
              onHideTextarea()
            }
          }

          if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'
            textareaRef.current.style.overflowY = 'hidden'
          }
        },
      },
    )
  }

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'

      const maxHeight = 7 * parseFloat(getComputedStyle(textareaRef.current).lineHeight)
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        maxHeight,
      )}px`

      if (textareaRef.current.scrollHeight > maxHeight) {
        textareaRef.current.style.overflowY = 'scroll'
      } else {
        textareaRef.current.style.overflowY = 'hidden'
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && isFocused) {
      e.preventDefault()
      handleSendComment()
    }
  }

  React.useEffect(() => {
    setComment(initialText)
  }, [initialText])

  React.useEffect(() => {
    if (isFocused && textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [isFocused])

  React.useEffect(() => {
    const displayAnswerId = searchParams.get('display-answer-id')
    const commentParam = searchParams.get('comment')

    if (displayAnswerId === answerId && commentParam === 'true' && !hasScrolled) {
      if (textareaRef.current) {
        const offset =
          window.innerHeight / 2 - textareaRef.current.getBoundingClientRect().height / 2
        window.scrollTo({
          top: textareaRef.current.getBoundingClientRect().top + window.scrollY - offset,
          behavior: 'smooth',
        })
        onFocus?.()
        setHasScrolled(true)
      }
    }
  }, [searchParams, answerId, hasScrolled, onFocus])

  return (
    <div className="relative w-full">
      <motion.div
        initial={{ height: 48 }}
        animate={{ height: isFocused ? 'auto' : 48 }}
        className={tw(
          'bg-background-primary rounded-4 relative px-4 py-2 ',
          isFocused ? '' : 'flex items-center',
        )}
        onClick={onFocus}
      >
        <textarea
          ref={textareaRef}
          placeholder="Add comment to this answer"
          value={comment}
          onChange={e => {
            setComment(e.target.value)
            onTextChange(e.target.value)
            adjustTextareaHeight()
          }}
          onKeyDown={handleKeyDown}
          className="bg-background-primary w-full flex-grow resize-none overflow-y-auto text-base text-gray-700 placeholder-gray-600 outline-none"
          rows={1}
          maxLength={5000}
        />

        <div className={tw('flex items-center justify-between', isFocused && 'mt-2')}>
          <div className="flex items-center">
            {!selectedImages[commentParentId || 'main'] ? (
              <>
                <input
                  type="file"
                  id={`imageUpload-${commentParentId || 'main'}`}
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                />
                <IconButton
                  variant="transparent"
                  onClick={() =>
                    document.getElementById(`imageUpload-${commentParentId || 'main'}`)?.click()
                  }
                >
                  <PhotoPlusIcon className="h-4 w-4 text-gray-600" />
                </IconButton>
              </>
            ) : null}

            <Popover.Root>
              <Popover.Trigger asChild>
                <IconButton variant="transparent">
                  <EmojiPlusIcon className="h-4 w-4 text-gray-600" />
                </IconButton>
              </Popover.Trigger>
              <Popover.Content className="z-50">
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  skinTonesDisabled={true}
                  allowExpandReactions={false}
                  searchDisabled={true}
                />
              </Popover.Content>
            </Popover.Root>
          </div>

          {isFocused && comment.length > 1 && !loading ? (
            <div className="flex items-center">
              <IconButton
                variant="transparent"
                className={tw('ml-auto', 'text-blue-500 hover:text-blue-300')}
                onClick={handleSendComment}
                disabled={imageLoading}
              >
                <SendIcon className="h-5 w-5" />
              </IconButton>
            </div>
          ) : null}
          {loading ? (
            <div className="flex items-center">
              <SpinnerIcon className="h-5 w-5 animate-spin" />
            </div>
          ) : null}
        </div>
      </motion.div>

      {selectedImages[commentParentId || 'main'] ? (
        <div className="mt-4 flex flex-wrap gap-2">
          <div className="relative">
            <Image
              src={URL.createObjectURL(selectedImages[commentParentId || 'main'] as File)}
              alt="Selected"
              width={96}
              height={96}
              className="h-24 w-24 rounded-lg object-cover"
            />
            <IconButton
              variant="transparent"
              className="absolute -right-2 top-0 size-auto rounded-full bg-red-600 p-1 text-white disabled:cursor-not-allowed disabled:bg-gray-400"
              onClick={() => deleteImage(commentParentId)}
              disabled={loading}
            >
              <DeleteIcon className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
      ) : null}
    </div>
  )
}
